import { useTranslation } from "react-i18next";
import IntroPage from "../components/IntroPage";
import DefaultLayout from "../layout/DefaultLayout";
import emailjs from '@emailjs/browser';
import { useState } from "react";
import SocialLinks from "../components/SocialLinks";
const Contacts = () => {

    const { t } = useTranslation();

    const [getInTouch, setGetInTouch] = useState({
      name: "",
      email: "",
      message: "",
      subject: "",
    });

    const [feedback, setFeedback] = useState({
      message: ''
    });

    const currentBreadcrum = [
        {
            label: 'Home',
            href: '/'
        },
        {
            label: t('Contacts.PageTitle'),
            href: '/contact-us'
        }
    ];

    const sendEmail = () => {
      emailjs.send('service_bkmr8nw', 'template_22cvfxk', { from_name: getInTouch.name, from_email: getInTouch.email, message: getInTouch.message, subject: getInTouch.subject}, {
        publicKey: 'pwkTCML5cXtY0gPJq',
      })
      .then(
        () => {
          emailjs.send('service_bkmr8nw', 'template_s9rx9ol', { to_name: getInTouch.name, to_email: getInTouch.email}, {
            publicKey: 'pwkTCML5cXtY0gPJq',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              setFeedback({  message: 'Messaggio inviato con successo!' });
            },
            (error) => {
              console.log('FAILED...', error);
              setFeedback({  message: 'Si è verificato un errore, riprova più tardi...' });
            },
          );
        },
        (error) => {
          console.log('FAILED...', error);
          setFeedback({  message: 'Si è verificato un errore, riprova più tardi...' });
        },
      );
  };
    
    return(
        <DefaultLayout>
            <div id="content">
                <IntroPage title="Contacts.PageTitle" caption="" links={currentBreadcrum} />
                <div className="form-contact style-contact bg-on-surface pt-100 pb-100">
        <div className="container"> 
          <div className="row flex-between row-gap-32">
            <div className="col-12 col-lg-5">
              <div className="infor">
                <div className="heading d-flex flex-column row-gap-16">
                  <div className="heading4 text-white">{t('Contacts.Title')}</div>
                  <div className="body3 text-placehover">{t('Contacts.Caption')}</div>
                  <SocialLinks />
                </div>
                <div className="list-more-infor mt-40">
                  <div className="item flex-item-center gap-12"><i className="ph ph-phone text-white bg-purple p-8 bora-50"></i>
                    <div className="line-y"> </div>
                    <div className="body2 text-white">+39 06 8716 5289</div>
                  </div>
                  <div className="item flex-item-center gap-12 mt-20"><i className="ph-bold ph-envelope-simple-open text-white bg-purple p-8 bora-50"></i>
                    <div className="line-y"> </div>
                    <div className="body2 text-white">info@maindshield.com</div>
                  </div>
                  <div className="item flex-item-center gap-12 mt-20"><i className="ph-bold ph-map-pin-line text-white bg-purple p-8 bora-50"></i>
                    <div className="line-y"> </div>
                    <div className="body2 text-white"><p>{t('Contacts.HeadOffice.Label')}</p> via di Donna Olimpia, 166, 00152, Roma</div>
                    <div className="body2 text-white"><p>{t('Contacts.HeadQuarter.Label')}</p> viale di Città d'Europa, 679, 00144, Roma</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="form-block flex-columns-between gap-20 p-28">
                <div className="row row-gap-20">
                  <div className="col-12 col-sm-6">
                    <input onChange={(e) => setGetInTouch({...getInTouch, name: e.target.value })} value={getInTouch.name} className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder={t('Contacts.Form.Placeholders.Name')}/>
                  </div>
                  <div className="col-12 col-sm-6">     
                    <input onChange={(e) => setGetInTouch({...getInTouch, subject: e.target.value })} value={getInTouch.subject} className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder={t('Contacts.Form.Placeholders.Subject')}/>
                  </div>
                  <div className="col-12">
                    <input onChange={(e) => setGetInTouch({...getInTouch, email: e.target.value })} value={getInTouch.email} className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="email" placeholder={t('Contacts.Form.Placeholders.Email')}/>
                  </div>
                  <div className="col-12"> 
                    <textarea onChange={(e) => setGetInTouch({...getInTouch, message: e.target.value })} value={getInTouch.message} className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8 display-block" name="messsage" cols="10" rows="4" placeholder={t('Contacts.Form.Placeholders.Message')}></textarea>
                  </div>
                </div>
                <div className="col-12 mt-20">
                  { feedback.message !== '' ? <p>{feedback.message}</p> : <></> }
                </div>
                <div className="button-block mt-20">
                  <button onClick={sendEmail} className="button w-100 bg-purple button-purple-hover text-white"> <span> <span></span></span><span className="bg-purple">{t('Contacts.Form.Submit')}</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-block">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5941.64972815614!2d12.449630291296268!3d41.875114391460215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13258aa09d6bdc79%3A0xcc11bce9b54643d!2sVia%20di%20Donna%20Olimpia%2C%20166%2C%2000152%20Roma%20RM!5e0!3m2!1sit!2sit!4v1713963874183!5m2!1sit!2sit" style={{ border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
            </div>
        </DefaultLayout>
    );
}

export default Contacts;
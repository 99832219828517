import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Pricing = () => {
    const { t, i18n } = useTranslation();

    const plans = {
        monthly: [
            {
                title: "Pricing.Light.Title",
                caption: "Pricing.Light.Caption",
                textButton: 'button',
                price: 0.00,
                features: [
                    {
                        text: "API",
                        included: true
                    },
                    {
                        text: "TEST",
                        included: false
                    }
                ]
            },
            {
                title: "Pricing.Prime.Title",
                caption: "Pricing.Prime.Caption",
                textButton: 'button',
                price: 19.99,
                features: [
                    {
                        text: "API",
                        included: true
                    },
                    {
                        text: "TEST",
                        included: false
                    }
                ]
            }
        ],
        yearly: [
            {
                title: "Pricing.Light.Title",
                caption: "Pricing.Light.Caption",
                textButton: "button",
                price: 0.00,
                features: [
                    {
                        text: "API",
                        included: true
                    },
                    {
                        text: "TEST",
                        included: false
                    }
                ]
            },
            {
                title: "Pricing.Prime.Title",
                caption: "Pricing.Prime.Caption",
                textButton: "button",
                price: 89.99,
                features: [
                    {
                        text: "API",
                        included: true
                    },
                    {
                        text: "TEST",
                        included: true
                    }
                ]
            }
        ]
    };

    const [viewPlan, setViewPlan] = useState('monthly');
    const [priceLocale, setPriceLocale] = useState('USD');

    const changePlan = () => {
        if (viewPlan === 'monthly') {
            setViewPlan('yearly');
        }
        else if (viewPlan === 'yearly') {
            setViewPlan('monthly')
        }
        else throw new Error('Error while switching plan')
    }

    useEffect( () => {
        switch(i18n.language) {
            case 'en':
            case 'en-US':
                setPriceLocale('USD')
                break;
            case 'it':
            case 'it-IT':
            case 'it-it':
                setPriceLocale('EUR');
                break;
            default:
                setPriceLocale('USD');
                break;
        }
    }, [i18n.language]);

    useEffect( () => {
        let USDollar = new Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: priceLocale,
        });
    }, [priceLocale]);
     return(
        <div class="section-pricing style-one pt-100">
        <div class="bg-blur"></div>
        <div class="container"> 
        <div class="bg-blur"></div>
        <div class="row flex-between row-gap-40">
            <div class="col-xl-3 col-12">
            <div class="heading">
                <div class="heading3 text-white">{t('Pricing.Title')}</div>
                <img src="/assets/images/logo.png" height={120} width={120} />
                <div class="body2 text-placehover mt-16">{t('Pricing.Caption')}</div>
                <div class="choose-type bg-line-dark bora-8 p-8 flex-between gap-8 mt-32 display-inline-flex">
                <button onClick={changePlan} className={`button text-white text-button-small bg-transparent pt-12 pb-12 pl-16 pr-16 ${viewPlan === 'monthly' ? 'active' : ''}`} data-name="monthly">{t('Pricing.PayMonthly')}</button>
                <button onClick={changePlan} class={`button text-white text-button-small bg-transparent pt-12 pb-12 pl-16 pr-16 ${viewPlan === 'yearly' ? 'active' : ''}`} data-name="yearly">{t('Pricing.PayYearly')}</button>
                </div>
            </div>
            </div>
            <div class="col-xl-9 col-12 pl-65">
            <div class="list-pricing" data-name="monthly">
                <div class="row row-gap-32">
                    {plans[viewPlan].map( (plan, indexPlan) => {
                        return(
                            <div key={`pricing-plan-${indexPlan}`} class="col-md-6 col-12">
                            <div class="pricing-item bg-line-dark p-40 bora-20 h-100">
                            <div class="heading5 text-white">{t(plan.title)}</div>
                            <div class="body3 text-placehover mt-12">{t(plan.caption)}</div>
                            <div class="heading2 text-white mt-20">
                            **,**
                            {
                            /*new Intl.NumberFormat(i18n.language, {
                                style: 'currency',
                                currency: priceLocale,
                            }).format(viewPlan !== 'yearly' ? plan.price : (plan.price / 12))
                            */}
                            <span style={{ fontSize: '1.2rem', textTransform: 'lowercase' }} className="pricing-label">{t('Pricing.EveryMonth.Label')}</span>
                            </div><a class="button text-white w-100 mt-24" href="/pricing"> <span> <span> </span></span><span class="bg-line-dark">Acquista</span></a>
                            <div class="list-feature d-flex flex-column gap-12 mt-40">
                                {
                                    plan.features.map( (f, indexFeature) => {
                                        return(
                                            <div key={`pricing-feature-${indexFeature}`} class="item flex-item-center gap-16">
                                            {
                                                f.included ?
                                                <>
                                                <i class="ph-bold ph-check fs-12 text-white p-8 bora-50 bg-blue"></i>
                                                <div class="feature text-white">{f.text}</div>
                                                </>
                                                :
                                                <>
                                                <i class="ph ph-x fs-12 p-8 bora-50 bg-placehover text-white"></i>
                                                <div class="feature text-placehover">{f.text}</div>
                                                </>
                                            }
                                            </div>
                                        )
                                    })
                                }                                
                            </div>
                            </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    );
}
export default Pricing;
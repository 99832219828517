import { useTranslation } from "react-i18next";
import IntroPage from "../components/IntroPage";
import DefaultLayout from "../layout/DefaultLayout";

const AboutUs = () => {
    const { t } = useTranslation();

    const currentBreadcrum = [
        {
            label: 'Home',
            href: '/'
        },
        {
            label: t('AboutUs.PageTitle'),
            href: '/about-us'
        }
    ];
    return(
        <DefaultLayout>
            <div id="content">
                <IntroPage title="AboutUs.PageTitle" caption="AboutUs.PageDescription" links={currentBreadcrum}/>
                <div class="about-us style-about pt-80 pb-80 bg-black-surface">
                    <div class="container">
                    <div class="row row-gap-40 flex-between">
                        <div class="col-12 col-lg-6 flex-column row-gap-20">
                        <div class="heading3">{t('AboutUs.Title1')}
                            <p>{t('AboutUs.Title2')}</p>
                        </div>
                        <div class="body2 text-placehover mt-16">{t('AboutUs.Caption1')}</div>
                        <div class="list-service mt-32">
                            <div class="service-item flex-item-center"><i class="ph-bold ph-check text-purple fs-24"> </i>
                            <div class="heading7 pl-12">{t('AboutUs.Features1')}</div>
                            </div>
                            <div class="service-item flex-item-center mt-12"><i class="ph-bold ph-check text-purple fs-24"> </i>
                            <div class="heading7 pl-12">{t('AboutUs.Features2')}</div>
                            </div>
                            <div class="service-item flex-item-center mt-12"><i class="ph-bold ph-check text-purple fs-24"> </i>
                            <div class="heading7 pl-12">{t('AboutUs.Features3')}</div>
                            </div>
                        </div>
                        <div class="button-block mt-32"><a class="button bg-purple button-purple-hover text-white" href="#!"> <span> <span> </span></span><span class="bg-purple">{t('AboutUs.FeaturesTextButton')}</span></a></div>
                        </div>
                        <div class="col-12 col-xl-5 col-lg-6 pl-65 pl-col-lg-0">
                        <div class="bg-img bora-16 overflow-hidden"><img class="w-100 d-block" src="assets/images/components/bg-about.png" alt=""/></div>
                        <div class="count flex-between mt-20">
                            <div class="item">
                            <div class="heading3 text-purple">350<span>+</span></div>
                            <div class="heading7 text-placehover mt-8">{t('AboutUs.Label1')}</div>
                            </div>
                            <div class="item">
                            <div class="heading3 text-purple">2500<span>+</span></div>
                            <div class="heading7 text-placehover mt-8">{t('AboutUs.Label2')}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row row-gap-40 flex-between mt-100">

                        <div class="col-12 col-xl-5 col-lg-6 pr-65 pr-col-lg-0">
                        <div class="bg-img bora-16 overflow-hidden"><img class="w-100 d-block" src="assets/images/components/bg-about.png" alt=""/></div>
                        </div>
                        <div class="col-12 col-lg-6 flex-column row-gap-20 text-center text-md-end">
                        <div class="heading3">{t('AboutUs.Title3')}
                            <p>{t('AboutUs.Title4')}</p>
                        </div>
                        <div class="body2 text-placehover mt-16">{t('AboutUs.Caption2')}</div>
                        <div class="button-block mt-32"><a class="button bg-purple button-purple-hover text-white" href="https://aicodify.it"> <span> <span> </span></span><span class="bg-purple">{t('AboutUs.TextButton1')}</span></a></div>
                        </div>
                    </div>
                    </div>
                    </div>
            </div>
        </DefaultLayout>
    );
}

export default AboutUs;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/animate.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/slick.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/style.css';
import './i18n';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import AboutUs from './pages/AboutUs';
import Contacts from './pages/Contacts';
import WaitingListSignUp from './pages/waitingList/WaitingListSignUp';
import DiscoverProduct from './pages/DiscoverProduct';


const router = createBrowserRouter([
  {
    path: '/',
    element:  <Home />
  },
  {
    path: '/faqs',
    element:  <FAQ />
  },
  {
    path: '/about-us',
    element:  <AboutUs />
  },
  {
    path: '/contacts',
    element:  <Contacts />
  },
  {
    path: '/waiting-list/signup',
    element:  <WaitingListSignUp />
  },
  {
    path: '/discover-product',
    element:  <DiscoverProduct />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}>
    </RouterProvider>
);
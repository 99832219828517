import CheckYourEmail from "../components/CheckYourEmail";
import DefaultLayout from "../layout/DefaultLayout";
import { useTranslation } from 'react-i18next';
import Pricing from "../components/Pricing";
function Home() {
    const { t } = useTranslation();
    const keypoints = [
        [
            "CredentialMonitoring",
            "DataLeakDetection",
            "PhishingDetection",
            "IntuitiveDashboard"    
        ],
    ];

    return(
        <DefaultLayout>
            <div id="content">
                <div class="slider-block style-one">
                    <div class="slider-main">
                    <div class="container"> 
                        <div class="row"> 
                        <div class="col-xl-6 content-wrapper">
                            <div class="text-content">
                            <p style={{ color: '#fff', fontSize: 70 }}>MAIND</p>
                            <div class="text-gradient-primary heading1 headIntro scroll-bottom-to-top2">{t('Home.Hero.Title')}</div>
                            <div class="text-white mt-40" style={{ fontSize: '27px', lineHeight: '32px'}}>
                                { t('Home.Hero.Subtitle')}
                            </div><a class="button button-blue-hover mt-40" href="/discover-product"> <span> <span></span></span><span class="bg-blue">
                                { t('Home.Hero.CallToAction') }
                                <i class="ph-bold ph-arrow-up-right fs-18 flex-center"></i></span></a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="list-partner pt-40 pb-40"> 
                    <div class="container text-center">
                    <div class="heading7 text-center">{ t('Home.Customers.Text')}</div>
                    </div>
                    <div class="container gap-32 row-gap-32 flex-between flex-wrap mt-32">
                    <div class="partner-item"><img src="assets/images/partners/1.png" alt="" width={100} height={100} /></div>
                    <div class="partner-item"><img src="assets/images/partners/2.png" alt="" width={100} height={100} /></div>
                    <div class="partner-item"><img src="assets/images/partners/3.png" alt="" width={100} height={100} /></div>
                    <div class="partner-item"><img src="assets/images/partners/4.png" alt="" width={100} height={100}  /></div>
                    <div class="partner-item"><img src="assets/images/partners/5.png" alt="" width={100} height={100} /></div>
                    </div>
                </div>
                <CheckYourEmail />
                <div class="about-us style-one pt-100 pb-100 bg-black-surface">
                    <div class="container pb-60">
                    <div class="row row-gap-40 flex-between">
                        <div class="col-12 col-lg-5 flex-column row-gap-20">
                        <div class="heading3">
                            { t('Home.ProductIntro.Title') }
                        </div>
                        <div class="body2 text-placehover mt-16">{t('Home.ProductIntro.Description')}</div>
                        <div class="list-service mt-32">
                            <div class="service-item flex-item-center scroll-right-to-left1"><i class="ph-bold ph-check text-blue fs-24"> </i>
                            <div class="heading7 pl-12">{t('Home.ProductIntro.BulletPoints.CredentialMonitoring')}</div>
                            </div>
                            <div class="service-item flex-item-center mt-12 scroll-right-to-left2"><i class="ph-bold ph-check text-blue fs-24"> </i>
                            <div class="heading7 pl-12">{t('Home.ProductIntro.BulletPoints.PhishingDetection')}</div>
                            </div>
                            <div class="service-item flex-item-center mt-12 scroll-right-to-left3"><i class="ph-bold ph-check text-blue fs-24"> </i>
                            <div class="heading7 pl-12">{t('Home.ProductIntro.BulletPoints.DataLeakAssessment')}</div>
                            </div>
                        </div>
                        <div class="button-block mt-32"><a class="button button-blue-hover" href="/discover-product"><span> <span></span></span><span class="pt-16 pb-16 bg-blue">{t('Home.ProductIntro.CallToAction')}<i class="ph-bold ph-arrow-up-right fs-18 flex-center"></i></span></a></div>
                        </div>
                        <div class="col-12 col-xl-6">
                            <div class='bg'></div>
                        <div class="bg-img"><img src="assets/images/components/DISPOSITIVO_SHOW.png" alt=""/>
                        </div>
                        <img src="assets/images/components/line-about1.png" alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="service-block list-service style-one pt-100"> 
                    <div class="container"> 
                    <div class="heading text-center"> 
                        <div class="heading3 text-center">{t('Home.KeyPoints.Title')}</div>
                        <div class="body2 text-placehover mt-12 text-center">{t('Home.KeyPoints.Description')}</div>
                    </div>
                    {
                        keypoints.map(
                            keypoint => {
                                return(
                                    <div class="list row row-gap-32 mt-40">
                                        {keypoint.map( itemKeyPoint => {
                                            return(
                                                <div class="col-lg-3 col-sm-6 scroll-bottom-to-top1">
                                                    <div class="service-item hover-box-shadow pl-32 pr-32 pt-24 pb-24 bora-24 h-100"><a class="service-item-main" href="/discover-product">
                                                        <div class="heading"><i class="icon-chart icon-white fs-60"></i></div>
                                                        <div class="desc mt-24">
                                                        <div class="heading7">{t(`Home.KeyPoints.KeyPoints.${itemKeyPoint}.Title`)}</div>
                                                        <div class="text-placehover mt-4">{t(`Home.KeyPoints.KeyPoints.${itemKeyPoint}.Description`)}</div>
                                                        </div></a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        )
                    }
                    </div>
                </div>
                <div class="how-it-work pt-100 pb-100"> 
                    <div class="container"> 
                    <div class="row heading flex-between row-gap-8">
                        <div class="col-lg-6"> 
                        <div class="heading3"><span className="text-gradient-primary deg45">{t('Home.DashboardIntro.GradientText')}</span><p>{t('Home.DashboardIntro.Title')}</p></div>
                        </div>
                        <div class="col-lg-5">
                        <div class="body1 text-placehover">{t('Home.DashboardIntro.Subtitle')}</div>
                        </div>
                    </div>
                    <div class="row flex-between mt-40 row-gap-40">
                        <div class="col-lg-4">
                        <div class="feature-item flex-item-center gap-24 scroll-bottom-to-top1">
                            <div class="infor"> 
                            <div class="heading6">{t('Home.DashboardIntro.Features.UserFriendly.Title')}</div>
                            <div class="text-placehover mt-8">{t('Home.DashboardIntro.Features.UserFriendly.Description')}</div>
                            </div>
                        </div>
                        <div class="feature-item flex-item-center gap-24 mt-40 scroll-bottom-to-top2">
                            <div class="infor"> 
                            <div class="heading6">{t('Home.DashboardIntro.Features.DataAnalytics.Title')} </div>
                            <div class="text-placehover mt-8">{t('Home.DashboardIntro.Features.DataAnalytics.Description')}</div>
                            </div>
                        </div>
                        <div class="feature-item flex-item-center gap-24 mt-40 scroll-bottom-to-top3">
                            <div class="infor"> 
                            <div class="heading6">{t('Home.DashboardIntro.Features.RealTimeUpdates.Title')}</div>
                            <div class="text-placehover mt-8">{t('Home.DashboardIntro.Features.RealTimeUpdates.Description')}</div>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-7"> 
                        <div class="bg-img bora-24 overflow-hidden"><img class="w-100" src="assets/images/components/bg-how-it-work.png" alt=""/>
                            <div class="count bg-blue bora-12 flex-item-center gap-60 pt-20 pb-20 pl-32 pr-32 scroll-left-to-right4">
                            <div class="item">
                                <div class="heading4">1.77<span>k+</span></div>
                                <div class="text-button">Business Problem </div>
                            </div>
                            <div class="item">
                                <div class="heading4">2.3<span>k+</span></div>
                                <div class="text-button">Business Setup </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {
                    /**
                <div class="testimonial-block style-one">
                    <div class="container">
                    <div class="heading3 text-center">What’s people say’s</div>
                    <div class="main-cmt bg-line-dark pl-16 pr-16 pb-60 bora-44 mt-40">
                        <div class="row flex-center">
                        <div class="col-xl-8 col-lg-9 col-md-10">
                            <div class="list-avatar">
                            <div class="prev-btn w-40 h-40 flex-center border-white bora-50"><i class="ph ph-caret-left fs-20 d-block"></i></div>
                            <div class="avatar">
                                <div class="item" data-name="0">
                                <div class="bg-img"><img src="assets/images/avatar/76x76.png" alt=""/></div>
                                </div>
                                <div class="item" data-name="1">
                                <div class="bg-img"> <img src="assets/images/avatar/76x76.png" alt=""/></div>
                                </div>
                                <div class="item" data-name="2">
                                <div class="bg-img"> <img src="assets/images/avatar/76x76.png" alt=""/></div>
                                </div>
                                <div class="item" data-name="3">
                                <div class="bg-img"> <img src="assets/images/avatar/76x76.png" alt=""/></div>
                                </div>
                                <div class="item" data-name="4">
                                <div class="bg-img"> <img src="assets/images/avatar/76x76.png" alt=""/></div>
                                </div>
                                <div class="item" data-name="5">
                                <div class="bg-img"> <img src="assets/images/avatar/76x76.png" alt=""/></div>
                                </div>
                            </div>
                            <div class="next-btn w-40 h-40 flex-center border-white bora-50"><i class="ph ph-caret-right fs-20 d-block"></i></div>
                            </div>
                            <div class="list-comment">
                                {
                                    testimonials.map( (testimonial, indexTestimonial) => {
                                        return(
                                            <div class="cmt-item text-center active" data-name="0">
                                                <div class="heading6 fw-500">{t(`Home.Testimonials.${indexTestimonial}.Quote`)}</div>
                                                <div class="text-button mt-24">{t(`Home.Testimonials.${indexTestimonial}.Name`)}</div>
                                                <div class="caption1 text-placehover mt-4">{t(`Home.Testimonials.${indexTestimonial}.Title`)}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                     */
                }
                {/**
                <div class="projects-block pt-100"> 
                    <div class="container"> 
                    <div class="bg-blur"></div>
                    <div class="heading text-center">
                        <div class="heading3 text-center">Our Gallery of Innovative Artworks</div>
                        <div class="list-nav flex-center mt-24">
                        <div class="nav-item text-button pt-8 pb-8 pl-16 pr-16 bora-44 pointer" data-name="all">All</div>
                        <div class="nav-item text-button pt-8 pb-8 pl-16 pr-16 bora-44 pointer" data-name="anime">Anime</div>
                        <div class="nav-item text-button pt-8 pb-8 pl-16 pr-16 bora-44 pointer active" data-name="creative">Creative</div>
                        <div class="nav-item text-button pt-8 pb-8 pl-16 pr-16 bora-44 pointer" data-name="pixel">Pixel</div>
                        <div class="nav-item text-button pt-8 pb-8 pl-16 pr-16 bora-44 pointer" data-name="illustration">Illustration</div>
                        </div>
                    </div>
                    <div class="list row row-gap-32 mt-40">
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top1" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top2" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top3" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top4" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top5" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top6" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top7" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter scroll-bottom-to-top8" data-name="creative">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="anime">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="anime">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="anime">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="anime">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="pixel">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="pixel">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="pixel">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="pixel">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="illustration">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 item-filter hide" data-name="all">
                                <div class=" item">
                                <div class="bg-img bora-20 overflow-hidden"><img class="w-100" src="assets/images/projects/300x300.png" alt="AI financial management"/></div><a class="infor p-12" href="project-detail.html">
                                    <div class="text-button-uppercase text-secondary">Marketting</div>
                                    <div class="heading7 text-on-surface mt-4">AI financial management</div></a>
                                </div>
                        </div>
                    </div>
                    </div>
                </div>
*/}
                <Pricing />
                {
                    /**
                <div id="popup-newsletter-block">
                    <div class="popup-newsletter-main">
                    <div class="bg-img"> <img class="w-100 h-100" src="assets/images/components/bg-popup-newsletter.png" alt=""/></div>
                    <div class="content p-40 bg-on-surface"> 
                        <div class="close-block text-end"><i class="ph-bold ph-x d-block fs-18 text-white pointer"></i></div>
                        <div class="heading6 text-white">News letter</div>
                        <div class="text-placehover mt-8">Sign up to get all the latest AIZAN news, website updates, offers and promos.</div>
                        <form class="mt-32">
                        <div class="form-input">
                            <input class="bg-line-dark text-white" type="text" placeholder="Email"/>
                            <button><i class="ph ph-paper-plane-tilt text-placehover"></i></button>
                        </div>
                        <div class="flex-item-center gap-8 mt-12">
                            <input class="prevent-popup-input" type="checkbox" name="prevent"/>
                            <div class="caption1 text-placehover">Prevent this Pop-up</div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                    * 
                     */
                }
                <a class="scroll-to-top-btn" href="#header"><i class="ph-bold ph-caret-up"></i></a>
            </div>
        </DefaultLayout>
    );
}

export default Home;
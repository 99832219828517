import { useTranslation } from "react-i18next";
import IntroPage from "../components/IntroPage";
import DefaultLayout from "../layout/DefaultLayout";

const DiscoverProduct = () => {
    const { t } = useTranslation();
    const currentBreadcrum = [
        {
            label: 'Home',
            href: '/'
        },
        {
            label: t('DiscoverProducts.PageTitle'),
            href: '/discover-product'
        }
    ];

    const products = [
        {
            name: "CredentialMonitoring",
            href: "#credentialmonitoring"
        },
        {
            name: "PhishingDetection",
            href: "#phishingdetection"
        },
        {
            name: "DataLeakAssessment",
            href: "#dataleakassess"
        },
        {
            name: "IntuitiveDashboard",
            href: "#intuitivedashboard"
        }
    ];

    return(
        <DefaultLayout>
            <div id="content">
                <IntroPage title="DiscoverProducts.PageTitle" caption="" links={currentBreadcrum} />
                <div class="style-service">
                    <div class="service-block list-service style-one pt-100 pb-100">
                    <div class="container"> 
                        <div class="heading text-center"> 
                        <div class="heading3 text-center">{t(`DiscoverProducts.Heading`)}</div>
                        <div class="body2 text-placehover mt-12 text-center">{t(`DiscoverProducts.Caption`)}</div>
                        </div>
                        <div class="list row row-gap-32 mt-40">
                            {
                                products.map( (product, indexProduct) => {
                                    return(
                                        <div class="col-lg-3 col-sm-6 scroll-bottom-to-top1">
                                                <div class="service-item hover-box-shadow pl-32 pr-32 pt-24 pb-24 bora-24 h-100"><a class="service-item-main" href={product.href}>
                                                    <div class="heading"><i class="icon-hand-grab icon-white fs-60"></i></div>
                                                    <div class="desc mt-24">
                                                        <div class="heading7">{t(`DiscoverProducts.Products.${product.name}.Title`)}</div>
                                                        <div class="text-placehover mt-4">{t(`DiscoverProducts.Products.${product.name}.Description`)}</div>
                                                    </div></a>
                                                </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </div>
                </div>
                <div id="credentialmonitoring" class="embracing-multilingual-support pt-100 pb-100"> 
                    <div class="container"> 
                    <div class="row row-gap-40">
                        <div class="col-lg-6"> 
                        <div class="heading3">{t(`DiscoverProducts.Products.CredentialMonitoring.Title`)}</div>
                        <div class="body2 text-placehover mt-24">{t(`DiscoverProducts.Products.CredentialMonitoring.Description`)}</div>
                        <div class="list-feature mt-32">
                        </div>
                        </div>
                        <div class="col-lg-6 pl-65 pl-col-lg-0">
                        <div class="bg-img bora-24 overflow-hidden"><img class="d-block w-100" src="/assets/images/components/monitorafifo-credenziali.png" alt=""/></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="phishingdetection" class="embracing-multilingual-support pt-100 pb-100"> 
                    <div class="container"> 
                    <div class="row row-gap-40">
                        <div class="col-lg-6 pr-65 pr-col-lg-0">
                            <div class="bg-img bora-24 overflow-hidden"><img class="d-block w-100" src="/assets/images/components/phishing.png" alt=""/></div>
                        </div>
                        <div class="col-lg-6"> 
                        <div class="heading3">{t(`DiscoverProducts.Products.PhishingDetection.Title`)}</div>
                        <div class="body2 text-placehover mt-24">{t(`DiscoverProducts.Products.PhishingDetection.Description`)}</div>
                        <div class="list-feature mt-32">
                        </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
                <div id="dataleakassess" class="embracing-multilingual-support pt-100 pb-100"> 
                    <div class="container"> 
                    <div class="row row-gap-40">
                        <div class="col-lg-6"> 
                        <div class="heading3">{t(`DiscoverProducts.Products.DataLeakAssessment.Title`)}</div>
                        <div class="body2 text-placehover mt-24">{t(`DiscoverProducts.Products.DataLeakAssessment.Description`)}</div>
                        <div class="list-feature mt-32">
                        </div>
                        </div>
                        <div class="col-lg-6 pl-65 pl-col-lg-0">
                        <div class="bg-img bora-24 overflow-hidden"><img class="d-block w-100" src="/assets/images/components/rilevamento_dataleak.png" alt=""/></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="intuitivedashboard" class="embracing-multilingual-support pt-100 pb-100"> 
                    <div class="container"> 
                    <div class="row row-gap-40">
                        <div class="col-lg-6 pr-65 pr-col-lg-0">
                            <div class="bg-img bora-24 overflow-hidden"><img class="d-block w-100" src="/assets/images/components/dashboard.png" alt=""/></div>
                        </div>
                        <div class="col-lg-6"> 
                        <div class="heading3">{t(`DiscoverProducts.Products.IntuitiveDashboard.Title`)}</div>
                        <div class="body2 text-placehover mt-24">{t(`DiscoverProducts.Products.IntuitiveDashboard.Description`)}</div>
                        <div class="list-feature mt-32">
                        </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default DiscoverProduct;
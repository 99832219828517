import { useTranslation } from "react-i18next";
import DefaultLayout from "../../layout/DefaultLayout";

const WaitingListSignUp = () => {
    const { t } = useTranslation();

    return(
        <DefaultLayout>
            <div id="content">
                <div class="slider-block style-one">
                <div class="slider-main waiting-list">
                <div class="container"> 
                    <div class="row block-form"> 
                    <div class="col-12 content-wrapper">
                    <div className="form-block flex-columns-between gap-20 p-28">
                        <div className="row row-gap-20">

                            <h3 className="text-center">{t('WaitingList.Signup.Form.Title')}</h3>

                            <input className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder={t('WaitingList.Signup.Form.Placeholders.Name')} />


                            <input className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="email" placeholder={t('WaitingList.Signup.Form.Placeholders.Email')} />

                            <input className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="tel" placeholder={t('WaitingList.Signup.Form.Placeholders.Mobile')} />

                        </div>
                        <div className="button-block mt-20">
                        <button  className="button w-100 bg-blue button-purple-hover text-white"> <span> <span></span></span><span className="bg-blue">{t('WaitingList.Signup.Form.Submit')}</span></button>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <a class="scroll-to-top-btn" href="#header"><i class="ph-bold ph-caret-up"></i></a>
            </div>
        </DefaultLayout>
    );
}

export default WaitingListSignUp;
import { useState } from "react";
import { useTranslation } from 'react-i18next';

function CheckYourEmail() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const createChatLi = (message, className) => {
        // Create a chat <li> element 
        const chatLi = document.createElement('li')
        chatLi.classList.add('chat', className)
        let chatContent = `<p></p>`
        chatLi.innerHTML = chatContent
        chatLi.querySelector('p').textContent = message
        return chatLi;
      }

    const checkEmail = async () => {
        if (email.trim() === '') return;
        const formChat = document.querySelector('.section-form-chat .form-chat')
        const chatBox = document.querySelector('.section-form-chat .chatbox')
        const chatInput = document.querySelector('.section-form-chat .form-chat input')
        chatInput.style.height = '30px'
        if (document.body.clientWidth < 576) {
            chatInput.style.height = '20px'
        }
        formChat.style.borderRadius = '99px'

        Array.from(chatBox.querySelectorAll('.chat')).forEach( message => {
            chatBox.removeChild(message);
        })
        
        setEmail('');
        chatBox.appendChild(createChatLi(email, 'outgoing'));
        try {
            let result = await fetch('https://walrus-app-m5mlk.ondigitalocean.app/maind/check-email', {
                headers: {
                    'Content-type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({ email: email })
            });
            let output = await result.json();
            if (result.status === 404) {
                chatBox.appendChild(createChatLi(t(output.message), 'incoming'));
            }
            else if (result.status === 200) {
                chatBox.appendChild(createChatLi(`${t('CheckEmail.FoundTextCount')}: ${output.count}`, 'incoming'));
            }
            else {
                chatBox.appendChild(createChatLi(t(output.message), 'incoming'));
            }
        }
        catch(e) {
            chatBox.appendChild(createChatLi(t('Common.GeneralError'), 'incoming'));
        }

    }
    return(
        <div class="section-form-chat pt-100 pb-100">
        <div class="container"> 
        <div class="heading2 text-center">{ t('Home.Demo.Title') }</div>
        <div class="body2 text-placehover text-center mt-24">{ t('Home.Demo.Description1') }</div>
        <div class="body2 text-placehover text-center">{ t('Home.Demo.Description2') }</div>
        <div class="row flex-center"> 
            <div class="col-sm-11"> 
            <div class="form-chat-block mt-60">
                <div class="form-content bora-16 overflow-hidden">
                <div class="content p-40 bg-on-surface">
                    <ul class="chatbox"> </ul>
                    <div class="form-chat p-12 bora-99 flex-between gap-16">
                    <input type="email" onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) checkEmail();
                    }} onChange={(e) => setEmail(e.target.value)} value={email} class="text-white" placeholder={t('Home.Demo.Placeholder')}></input><span onClick={checkEmail} class="flex-center" id="send-chat-btn"><i class="ph ph-paper-plane-tilt fs-20"></i></span>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    );
}

export default CheckYourEmail;
const Breadcrum = ({ links }) => {
    return(
        <div class="heading-nav flex-center gap-8">
            {
                links.map( (link, index) => {
                    return <>
                            <a class="hover-underline caption1 text-placehover" href={link.href}>{link.label}</a>
                            
                            {
                                index !== links.length - 1
                                ?
                                <i class="ph-fill ph-dot-outline text-purple"></i>
                                :
                                <></>
                            }
                        </>
                })
            }    
        </div>
    )
}
export default Breadcrum;
import { useTranslation } from "react-i18next";
import IntroPage from "../components/IntroPage";
import DefaultLayout from "../layout/DefaultLayout";

const FAQ = () => {
    const { t } = useTranslation();
    const currentBreadcrum = [
        {
            label: 'Home',
            href: '/'
        },
        {
            label: 'FAQs',
            href: '/faqs'
        }
    ];

    const questions = [
        {
            id: "Question1",
            open: false
        }
    ];

    const handleClick = (event, indexQuestion) => {
        event.stopPropagation();
        let parent = document.querySelector(`.question-item-${indexQuestion}`);
        let classList = Array.from(parent.classList);
        let icon = parent.querySelector('i')
        if (classList.includes('open')) {
            parent.classList.remove('open');
            icon.classList.replace('ph-minus', 'ph-plus')
        }
        else {
            parent.classList.add('open');
            icon.classList.replace('ph-plus', 'ph-minus')
        }
    }

    return(
        <DefaultLayout>
            <div id="content">
                <IntroPage title="Faqs.Title" caption="Faqs.Caption" links={currentBreadcrum}/>
                <div className="faqs-block pt-100 pb-100">
                    <div className="container">
                    <div className="row flex-center"> 
                        <div className="col-lg-6 col-md-9 col-sm-10">
                        <div className="heading3 text-white text-center">{t('Faqs.Title')}</div>
                        </div>
                        <div className="col-12 col-lg-9 col-sm-10 mt-40">
                        <div className="list-question"> 
                        {
                            questions.map(
                                (question, indexQuestion) => {
                                    return(
                                        <div key={`question-faq-${indexQuestion}`} className={`question-item question-item-${indexQuestion} pt-24 pb-24 pointer`} onClick={(e) => handleClick(e, indexQuestion)}>
                                            <div className="question-item-main flex-between heading7 text-placehover">
                                                {t(`Faqs.Questions.${question.id}.Question`)}
                                                <i className="ph-bold ph-plus fs-20 p-8"></i>
                                            </div>
                                            <div className="content-question">
                                                <div className="text-placehover pt-16">
                                                    {t(`Faqs.Questions.${question.id}.Answer`)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <a className="scroll-to-top-btn" href="#header"><i className="ph-bold ph-caret-up"></i></a>
            </div>
        </DefaultLayout>

    )
}

export default FAQ;
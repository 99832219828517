import { useState } from "react";
import { useTranslation } from "react-i18next";
const MobileMenu = ({ open, set }) => {

    const { t } = useTranslation();

    const [submenu, setSubmenu] = useState([
        {
          label: 'Header.SignUpNow',
          open: false,
          href: '/waiting-list/signup',
          exploreBlocks: [
          ]
      },  
      {
            label: 'Header.Home',
            open: false,
            href: '/',
            exploreBlocks: []
        },
        {
            label: 'Header.Explore',
            open: false,
            items: [
            ],
            exploreBlocks: [
                {
                    title: "Home.KeyPoints.KeyPoints.CredentialMonitoring.Title",
                    caption: "",
                    textButton: 'Common.Explore',
                    href: '/discover-product'
                },
                {
                  title: "Home.KeyPoints.KeyPoints.DataLeakDetection.Title",
                  caption: "",
                  textButton: 'Common.Explore',
                  href: '/discover-product'
                },
                {
                  title: "Home.KeyPoints.KeyPoints.PhishingDetection.Title",
                  caption: "",
                  textButton: 'Common.Explore',
                  href: '/discover-product'
                },
                {
                  title: "Home.KeyPoints.KeyPoints.IntuitiveDashboard.Title",
                  caption: "",
                  textButton: 'Common.Explore',
                  href: '/discover-product'
                }
            ]
        },
        {
          label: 'Header.Pages',
          open: false,
          items: [
            {
              title: 'Header.WhoAreUs',
              href: '/about-us'
            },
            {
              title: 'AICodify',
              href: 'https://aicodify.it'
            }
          ],
          exploreBlocks: []
        },
        {
          label: 'Header.Contacts',
          open: false,
          href: '/contacts',
          exploreBlocks: []
        }
    ])

    function closeMobileMenu() {
        set(false);
    }

    const handleSubMenu = (event, index, value) => {
        event.stopPropagation();
        let tempSubmenu = [...submenu];
        tempSubmenu[index] = {...tempSubmenu[index], open: value};
        setSubmenu(tempSubmenu)
    }

    return(
        <div id="menu-mobile-block" className={open ? 'open' : ''}>
        <div class="menu-mobile-main"> 
          <div class="heading flex-between">
            <a class="logo" href="/"> 
            <img alt="logo" src="/assets/images/logo.png" height={120} width={120} />
            </a>
            <div class="close-block"  onClick={closeMobileMenu}> <i class="ph-bold ph-x d-block fs-18 text-white pointer"></i></div>
          </div>
          <ul class="nav d-block">
            {
                submenu.map( (sub, index) => {
                    return(
                        <li onClick={(e) => handleSubMenu(e, index, true)} key={`submenu-mobile-${index}`}> <a class="text-subtitle text-white flex-between active" href={sub.items ? '#' : sub.href}><span>{t(sub.label)}</span>
                        {
                          sub.items ?
                          <i class="ph ph-caret-right text-white fs-12"></i>
                          :
                          <></>
                        }
                        </a>
                        {
                          sub.items ? 
                          <div class={`sub-nav-mobile ${sub.open ? 'open' : ''} bg-black-surface`}>
                          <div class="heading flex-between"> 
                            <div class="back-block pointer" ><i onClick={(e) => handleSubMenu(e, index, false)} class="ph ph-caret-left text-white fs-18 d-block"></i></div>
                            <div class="heading7 text-uppercase text-white">{t(sub.label)}</div>
                            <div class="close-block"> <i class="ph-bold ph-x d-block fs-18 text-white pointer" onClick={(e) => handleSubMenu(e, index, false)}></i></div>
                          </div>
                          <div class="list-home">
                            {
                                sub.items.map( item => {
                                    return(
                                        <a key={`submenu-nav-mobile-${index}-link`} class="display-block item" href={item.href}> <i class="icon-chat-bot text-white fs-32"> </i>
                                            <div class="text-button text-white">{t(item.title)}</div>
                                            <div class="caption1 text-placehover mt-4">{item.caption}</div>
                                        </a>
                                    )  
                                })
                            }
                            </div>
                            {
                                sub.exploreBlocks.length > 0 ? sub.exploreBlocks.map( (block, indexBlock) => {
                                    return(
                                        <div key={`submenu-${index}-exploreblock-${indexBlock}`} class="explore-block">
                                            <div class="main bg-line-dark p-16 bora-12">
                                            <div class="heading7 text-white">{t(block.title)}</div>
                                            <div class="text-placehover mt-12">{block.caption}</div><a class="button bg-blue button-blue-hover text-white mt-12" href={block.href}> <span> <span> </span></span><span class="bg-blue">{t(block.textButton)}</span></a>
                                            </div>
                                        </div>
                                    )
                                })
                                : <></>
                            }

                        </div>
                          :
                          <></>
                        }
                      </li>
                    )
                })
            }
            {/*
            <li><a class="text-subtitle text-white flex-between" href="#!"><span>Services</span><i class="ph ph-caret-right text-white fs-12"></i></a>
              <div class="sub-nav-mobile bg-black-surface">
                <div class="heading flex-between"> 
                  <div class="back-block pointer"><i class="ph ph-caret-left text-white fs-18 d-block"></i></div>
                  <div class="heading7 text-uppercase text-white">Services</div>
                  <div class="close-block"> <i class="ph-bold ph-x d-block fs-18 text-white pointer"></i></div>
                </div>
                <div class="list-home">
                  <div class="item"> <a class="flex-column-center" href="service-one.html">
                      <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="assets/images/submenu/182x182.png" alt=""/></div>
                      <div class="text-button text-white text-center mt-8">AI chat bot</div></a></div>
                  <div class="item mt-24"><a class="flex-column-center" href="service-two.html">
                      <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="assets/images/submenu/182x182.png" alt=""/></div>
                      <div class="text-button text-white text-center mt-8">AI Image generator</div></a></div>
                  <div class="item mt-24"><a class="flex-column-center" href="service-three.html">
                      <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="assets/images/submenu/182x182.png" alt=""/></div>
                      <div class="text-button text-white text-center mt-8">Digital Agency</div></a></div>
                  <div class="item mt-24"><a class="flex-column-center" href="service-four.html">
                      <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="assets/images/submenu/182x182.png" alt=""/></div>
                      <div class="text-button text-white text-center mt-8">Business Agency</div></a></div>
                </div>
                <div class="explore-block"> 
                  <div class="main bg-line-dark p-16 bora-12">
                    <div class="heading7 text-white">AI Create Image</div>
                    <div class="text-placehover mt-12">Praesent interdum lacus ac est viverra hendrerit. Aliquam dapibus, ante vitae matti gravida.</div><a class="button bg-blue button-blue-hover text-white mt-12" href="about.html"> <span> <span> </span></span><span class="bg-blue">Explore now</span></a>
                  </div>
                </div>
              </div>
            </li>
            <li><a class="text-subtitle text-white flex-between" href="#!"><span>Pages</span><i class="ph ph-caret-right text-white fs-12"></i></a>
              <div class="sub-nav-mobile bg-black-surface">
                <div class="heading flex-between"> 
                  <div class="back-block pointer"><i class="ph ph-caret-left text-white fs-18 d-block"></i></div>
                  <div class="heading7 text-uppercase text-white">Pages</div>
                  <div class="close-block"> <i class="ph-bold ph-x d-block fs-18 text-white pointer"></i></div>
                </div>
                <div class="list-pages"><a class="item flex-item-center gap-8" href="about.html"><i class="icon-infor icon-blue fs-28"> </i>
                    <div class="text-button text-white">About Us</div></a><a class="item flex-item-center gap-8 mt-16" href="pricing.html"><i class="icon-pricing icon-blue fs-28"> </i>
                    <div class="text-button text-white">Pricing</div></a><a class="item flex-item-center gap-8 mt-16" href="/faqs"><i class="icon-faq icon-blue fs-28"> </i>
                    <div class="text-button text-white">FAQs</div></a><a class="item flex-item-center gap-8 mt-16" href="page-not-found.html"><i class="icon-not-found icon-blue fs-28"> </i>
                    <div class="text-button text-white">404 Page</div></a></div>
                <div class="banner-infor bora-16 overflow-hidden">
                  <div class="bg-img"><img class="w-100 h-100 object-fit-cover" src="assets/images/components/bg-submenu.png" alt=""/></div>
                  <div class="text-content pt-24 pb-24 pl-32 pr-32"> 
                    <div class="heading7">AI Create Image</div>
                    <div class="mt-12">Praesent interdum lacus ac est viverra hendrerit. Aliquam dapibus, ante vitae matti gravida.</div><a class="button bg-blue button-blue-hover text-white mt-12" href="about.html"> <span> <span> </span></span><span class="bg-blue">Explore now</span></a>
                  </div>
                </div>
                <div class="explore-block"> 
                  <div class="main bg-line-dark p-16 bora-12">
                    <div class="list-social flex-item-center gap-12"><a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i class="icon-facebook display-block"></i></a><a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.linkedin.com/" target="_blank" rel="noreferrer"><i class="icon-linkedin fs-14 display-block"></i></a><a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.twitter.com/" target="_blank" rel="noreferrer"><i class="icon-twitter fs-12 display-block"></i></a><a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.instagram.com/" target="_blank" rel="noreferrer"><i class="icon-instagram fs-14 display-block"></i></a><a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.youtube.com/" target="_blank" rel="noreferrer"><i class="icon-youtube fs-12 display-block"></i></a></div>
                    <div class="text-placehover mt-16">Praesent interdum lacus ac est viverra hendrerit. Aliquam dapibus, ante vitae matti.</div>
                    <div class="mail flex-item-center gap-8 mt-16"><i class="ph-light ph-envelope text-white fs-20"></i><span class="caption1 text-white">info@maindshield.com</span></div>
                    <div class="phone flex-item-center gap-8 mt-16"><i class="ph-fill ph-phone text-white"></i><span class="fw-700 text-placehover">+39 06 8716 5289</span></div>
                  </div>
                </div>
              </div>
            </li>
            <li><a class="text-subtitle text-white flex-between" href="blog.html"><span>Blog</span><i class="ph ph-caret-right text-white fs-12"></i></a></li>
            <li><a class="text-subtitle text-white flex-between" href="contact.html"><span>Contact</span><i class="ph ph-caret-right text-white fs-12"></i></a></li>
          */}
          </ul>
        </div>
      </div>
    );
}

export default MobileMenu;
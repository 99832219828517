import { useTranslation } from "react-i18next";
import SocialLinks from "./SocialLinks";

function Footer() {
  const { t } = useTranslation();
    return(
        <div id="footer">
        <div class="footer-block bg-black-surface pt-60">
          <div class="container">
            <div class="heading flex-between">
              <div class="footer-company-infor d-flex flex-column gap-20">
                <a class="logo" href="">
                  <img src="/assets/images/logo.png" height={120} width={120} />
                </a>
              </div>
              <div class="footer-navigate">
                <ul class="flex-item-center gap-40">
                  <li class="flex-center"><a class="text-subtitle text-white" href="/">{t('Header.Home')}</a></li>
                  <li class="flex-center"><a class="text-subtitle text-white" href="/about-us">{t('Header.WhoAreUs')}</a></li>
                  <li class="flex-center"><a class="text-subtitle text-white" href="/contacts">{t('Header.Contacts')}</a></li>
                </ul>
              </div>
              <SocialLinks />
            </div>
            <div class="company-contact flex-center gap-16 mt-32 flex-wrap">
              <div class="phone flex-item-center gap-8"><i class="ph ph-phone text-placehover fs-24"></i><span class="text-button-uppercase text-placehover">+39 06 8716 5289</span></div><span class="text-placehover">|</span>
              <div class="mail flex-item-center gap-8"><i class="ph-light ph-envelope text-placehover fs-24"></i><span class="text-button-uppercase text-placehover">info@maindshield.com</span></div><span class="text-placehover">|</span>
              <div class="location flex-item-center gap-8"><i class="ph-light ph-map-pin text-placehover fs-24"></i><span class="text-button-uppercase text-placehover">via di Donna Olimpia, Roma</span></div>
            </div>
          </div>
          <div class="bg-black-surface mt-32">
            <div class="container bg-black-surface">
              <div class="line-dark"></div>
              <div class="footer-bottom flex-between pt-12 pb-12 flex-wrap">
                <div class="left-block flex-item-center">
                  <div class="copy-right text-placehover caption1">©2024 AICodify. All Rights Reserved.</div>
                </div>
                <div class="nav-link flex-item-center gap-8"><a class="text-placehover caption1 hover-underline" href="#!">Terms Of Services</a><span class="text-placehover caption1">|</span><a class="text-placehover caption1 hover-underline" href="#!">Privacy Policy</a><span class="text-placehover caption1">|</span><a class="text-placehover caption1 hover-underline" href="#!">Cookie Policy</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Footer;
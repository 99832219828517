const SocialLinks = () => {
    return(        
        <div class="list-social flex-item-center gap-12">
            <a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.facebook.com/profile.php?id=61558845674557" target="_blank" rel="noreferrer">
                <i class="fa-brands fa-facebook"></i>
            </a>
            <a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.linkedin.com/company/102252314/admin/feed/posts/" target="_blank" rel="noreferrer">
                <i class="fa-brands fa-linkedin"></i>
            </a>
            <a class="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center" href="https://www.instagram.com/aicodify/" target="_blank" rel="noreferrer">
                <i class="fa-brands fa-instagram"></i>
            </a>
    </div>
    );
}
export default SocialLinks;
import { useState } from "react";
import MobileMenu from "./Menu/MobileMenu";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SocialLinks from "./SocialLinks";
function Header() {

    const { t } = useTranslation();
    const [mobileMenu, setMobileMenu] = useState(false);
    const router = useLocation();

    const openMobileMenu = () => {
      setMobileMenu(true);
    }

    return(
      
        <div id="header">
        <div class="header-menu style-one bg-black-surface">
          <div class="container"> 
            <div class="header-main flex-between">
              <div class="menu-main"> 
                <ul class="flex-item-center">
                  <li class="flex-center"><a className={`text-subtitle ${router.pathname === '/' ? 'active' : ''}`} href="/">{t('Header.Home')}</a>
                  </li>
                  <li class="flex-center"><a className={`text-subtitle ${router.pathname === '/discover-product' ? 'active' : ''}`} href="/discover-product">{t('Header.Explore')}<i class="fa-solid fa-angle-down fs-12 pl-4"></i></a>
                    <div class="sub-nav">
                      <div class="sub-nav-main bg-black-surface p-24">
                        <div class="row">
                          <div class="col-12">
                            <div class="list-home flex-between gap-30">
                              <div class="item"> <a class="flex-column-center" href="/discover-product">
                                  <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="/assets/images/components/monitorafifo-credenziali.png" alt=""/></div>
                                  <div class="text-button text-white text-center mt-8">{t('Home.KeyPoints.KeyPoints.CredentialMonitoring.Title')}</div></a></div>
                              <div class="item"> <a class="flex-column-center" href="/discover-product">
                                  <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="/assets/images/components/rilevamento_dataleak.png" alt=""/></div>
                                  <div class="text-button text-white text-center mt-8">{t('Home.KeyPoints.KeyPoints.DataLeakDetection.Title')}</div></a></div>
                              <div class="item"> <a class="flex-column-center" href="/discover-product">
                                  <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="/assets/images/components/phishing.png" alt=""/></div>
                                  <div class="text-button text-white text-center mt-8">{t('Home.KeyPoints.KeyPoints.PhishingDetection.Title')}</div></a></div>
                              <div class="item"> <a class="flex-column-center" href="/discover-product">
                                  <div class="bg-img bora-12 overflow-hidden"><img class="w-100" src="/assets/images/components/dashboard_lighning.png" alt=""/></div>
                                  <div class="text-button text-white text-center mt-8">{t('Home.KeyPoints.KeyPoints.IntuitiveDashboard.Title')}</div></a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="flex-center"><div class="text-subtitle">{t('Header.Pages')}<i class="fa-solid fa-angle-down fs-12 pl-4"></i></div>
                    <div class="sub-nav">
                      <div class="sub-nav-main bg-black-surface p-24">
                        <div class="row">
                          <div class="col-9">
                            <div class="list-pages p-24 flex-between bg-line-dark bora-16"><a class="item flex-item-center gap-8" href="/about-us"><i class="icon-infor icon-blue fs-28"> </i>
                                <div class="text-button text-white">{t('Header.WhoAreUs')}</div></a><a class="item flex-item-center gap-8" href="https://aicodify.it" target="_blank" rel="noreferrer"><i class="icon-pricing icon-blue fs-28"> </i>
                                <div class="text-button text-white">AICODIFY</div></a>
                                </div>
                            <div class="banner-infor bora-16 overflow-hidden mt-20">
                              <div class="bg-img w-100 h-100"><img class="w-100 h-100 object-fit-cover" src="/assets/images/components/bg-submenu.png" alt=""/></div>
                              <div class="text-content pt-24 pb-24 pl-32 pr-32"> 
                                <div class="heading7">{t('Home.ProductIntro.Title')}</div>
                                <div class="mt-12">{t('Home.ProductIntro.Description')}</div><a class="button bg-blue button-blue-hover text-white mt-12" href="/discover-product"> <span> <span> </span></span><span class="bg-blue">{t('Common.Explore')}</span></a>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="main-content bg-line-dark bora-12 h-100">
                              <div class="explore-block p-24 h-100">
                                <SocialLinks />
                                <div class="mail flex-item-center gap-8 mt-16"><i class="fa-solid fa-envelope"></i><span class="caption1 text-white">info@maindshield.com</span></div>
                                <div class="phone flex-item-center gap-8 mt-16"><i class="fa-solid fa-phone-flip"></i><span class="fw-700 text-placehover">+39 06 8716 5289</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="flex-center"><a className={`text-subtitle ${router.pathname === '/contacts' ? 'active' : ''}`} href="/contacts">{t('Header.Contacts')}</a></li>
                </ul>
              </div>
              <a class="logo" href="/">
                <img alt="logo" src="/assets/images/logo.png" height={120} width={120} />
              </a>
              <div class="right-block flex-item-center">
                <div class="menu-humburger display-none pr-24 pointer"><i onClick={openMobileMenu} class="ph ph-list text-white fs-24"></i></div><a class="button button-blue-hover text-white text-button" href="/waiting-list/signup"> <span> <span></span></span><span class="bg-blue">{t('Header.CallToAction')}</span></a>
              </div>
            </div>
            <MobileMenu open={mobileMenu} set={setMobileMenu}/>
          </div>
        </div>
      </div>
    );
}
export default Header;
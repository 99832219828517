import { useTranslation } from "react-i18next";
import Breadcrum from "./Breadcrum";
const IntroPage = ({ title, caption, links }) => {
    const { t } = useTranslation();

    return(
        <div class="breadcrum pt-80 pb-80">
            <div class="bg-img"> <img src="assets/images/slider/breadcrum.png" alt=""/></div>
            <div class="container"> 
                <div class="row flex-center">
                <div class="col-lg-7 col-md-8 col-sm-10">
                    <Breadcrum links={links}/>
                    <div class="text-nav text-center mt-12">
                    <div class="heading3 text-white text-center display-inline-block">{t(title)}
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="46" viewbox="0 0 38 46" fill="none">
                        <path d="M10.1501 28.6324C13.835 25.1233 24.0331 16.9879 35.3457 12.5187M13.8035 40.7067C17.0357 40.4323 25.4105 40.4869 33.0524 42.9013M2.95566 19.8167C4.12796 16.7921 7.85358 9.29141 13.3777 3.48513" stroke="#7D3CF3" stroke-width="5" stroke-linecap="round"></path>
                        </svg>
                    </div>
                    <div class="sub-heading mt-8 text-white text-center fw-400">{t(caption)}</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default IntroPage;